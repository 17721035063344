<template>
  <div class="seller" >
    <div class="mainbody">
      <div class="header-title"><p>申请开店 - 个人申请</p></div>
      <div class="header-text">
        <p>
          依据《中华人民共和国电子商务法》，91再生网依法进行主体登记，所有个人线上交易均需要注册个体工商户，并履行纳税义务。您可以按个人信息，提交身份证等信息，并授权我方进行个体工商户注册，注册成功后即可自动转为个体工商户账号。在未成功注册前个人交易主体无法正常交易与浏览。
        </p>
      </div>
      <!-- 进度条 -->

      <Steps :steps="steps" />

      <!-- 手机扫码 -->
      <div class="verify">
        <div class="QR-code">
          <div style="width: 258px; height: 258px">
            <img v-if="ver" :src="ver" alt="" />
          </div>
          <p class="title">请使用手机扫码进行公安部人脸实名校验</p>
        </div>
      </div>
      <a-row>
        <a-col :span="24" align="center" class="color184 "
          >若已完成手机端实名校验，请点击下一步！</a-col>
      </a-row>

      <div class="next">
        <p @click="showModal">下一步</p>
      </div>
      <!-- loading等待 -->
      <a-row>
        <a-modal
          :visible="visible"
          centered
          :closok="false"
          @cancel="handleCancel"
          :maskClosable="false"
          width="710px"
        >
          <p class="logo">
            <img src="../../assets/images/u20328.gif" alt="" />
          </p>
          <p class="spin-text">校验结果加载中，请耐心等待！</p>
        </a-modal>
      </a-row>
    </div>
  </div>
</template>
<script>
import { verify, face, isIndividual } from '../../api/seller'
import Steps from './component/steps.vue'
export default {
  components: {
    Steps
  },
  data() {
    return {
      ver: '', // 二维码
      visible: false, //loading
      steps: 0, //进度条
      // id: '', //身份证号
      bizNo: ''
    }
  },
  created() {
    this.getVerify()
  },
  methods: {
    // 获取二维码
    getVerify() {
      verify({}).then((res) => {
        this.bizNo = res.data.bizNo
        this.ver = res.data.pic
      })
    },
    // 获取活体检测结果
    getface() {
      
      face({ bizNo: this.bizNo }).then((res) => {
        this.visible = true
        if (res.code === 200) {         
          isIndividual({ idCard: res.data.id}).then((res) => {
            if (res.data) {
            this.$message.error("您已经申请过了，不能重复申请了") 
            this.visible=false  
            } else {
              this.$router.replace({
                path: '/perfectInfo',
                query: { bizNo: this.bizNo }
              })
            }
          })
          // this.$message.success(res.message)
        } else {
          this.visible = false
          this.$message.error(res.message)
        }
      })
      // 校验申请人是否已经申请
    },
    showModal() {
      // this.visible = true
      this.getface()
    },
    handleCancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.seller {
  margin-bottom: 100px;
  max-width: 1440px;
  min-width: 990px; 
   @media screen and (max-width: 1440px){
   padding: 0 15px;
   }
    margin: 20px auto;
  .mainbody {
    background-color: #ffffff;
    position: relative;
    .header-title {
      padding: 30px 50px;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.847058823529412);
      font-weight: 650;
    }
    .header-text {
      max-width: 1200px;
      height: 44px;
      line-height: 22px;
      padding: 0 0 0 50px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.647058823529412);
      text-indent: 2em;
      p{
        max-width: 1100px;
      }
    }
    .verify {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 55px 0;
      margin: auto;
      .QR-code {
        text-align: center;
        img {
          width: 100%;
        }
        .title {
          padding: 45px 0 0 0;
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
    .color184 {
      font-weight: 700;
      font-size: 16px;
      color: rgba(184, 116, 26, 0.996078431372549);
    }

    .next {
      display: flex;
      padding: 50px 60px 55px 50px;
      color: #ffffff;
      justify-content: center;
      :nth-child(1) {
        width: 137px;
        height: 40px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        border-radius: 4px;
        background-color: rgba(24, 144, 255, 1);
        line-height: 40px;
        text-align: center;
      }
    }
  }
}
.logo {
  width: 650px;
  img {
    width: 100%;
    height: 400px;
  }
  position: relative;
}
.spin-text {
  font-size: 16px;
  color: #666666;
  font-weight: 650;
  position: absolute;
  top: 320px;
  left: 250px;
}
/deep/.ant-modal-footer {
  display: none !important;
}
</style>